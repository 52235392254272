html{
  height: 100%;
}
body{
  height: 100%;
}
#root {
  color: #fff;
  height: 100%;
}
.app{
  height: 100%;
}
.app .container.mt-5{
  margin-bottom: 5.4rem;
}

/* footer{
  height: 100%;
}

footer .container{
  height: 100%;
} */
.navbar-brand img{
  width: 282px;
  height: 84px;
}
#footerLogo{
  width: 282px;
  height: 84px;
}
#headOne{
  padding: 0px 0px 30px 0px;
}
#headFourth{
  padding: 10px 0px 10px 0px;
  font-weight: 300;
}
.card-group .card.text-dark.bg-warning{
  max-width: 18rem;
  margin: 10px;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
}
.card-group .card-header{
  background-color: #0000000a;
  border-bottom: unset;
}

#myVideo {
  border-radius:5px;
  width:100%;
}

/* Video Style */
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1; /* Ensure it appears above other content */
  background-color: #00000070;
  transition: right 0.4s, opacity 0.4s;
}
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: cover; /* Cover the entire viewport */
}



/* Footer */
#footerContainer{
  padding: 0px;
    margin: 0px;
    max-width: -webkit-fill-available;
}
#footerContainer .row{
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
#footerContainer .col-md-12{
  padding: 0;
}
.footer{
	padding: 60px 0;
    width: 100%;
    background: #0000001f;
    color: #fff;
   
}
.footer-title{
	position: relative;
    color: #fff;
	font-size: 24px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 20px;
    border-bottom: 1px solid #4e4e4e;
}
.footer-title:after{
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	width: 30px;
	height: 4px;
	background: #ff304d;
}
.footer-links a{
	padding: 10px 0;
	border-bottom: 1px solid #404040;
	color: #fff;
	display: block;
	transition: color 0.5s ease-in-out;
   text-decoration:none;
}
.footer-links a:hover{
	color: #ff304d;
}
.footer-social-links li{
	display: inline-block;
}
.footer-social-links a{
	/* width: 40px;
	height: 40px;
	font-size: 16px; */
	float: left;
  margin-top: 10px;
	margin-right: 10px;
	/* padding: 10px; */
	border: 1px solid #40404000;
	/* border-radius: 50px; */
	text-align: center;
	color: #fff;
	/* line-height: 1; */
	/* -webkit-transition: background 0.3s ease-in-out;
	-moz-transition: background 0.3s ease-in-out;
	transition: background 0.3s ease-in-out; */
  /* background:#3b008d00; */
}
.footer-social-links a:hover{
	/* background: #ff304d; */
}
/* g. Footer bottom section */
.footer-bottom{
	width: 100%;
	padding: 25px 0;
	text-align: center;
	color: #fff;
	background: #000;
}
.socialIcon{
  font-size: 24px;
}
.footerLinks{
  cursor: pointer;
}
.footerLinks:hover{
	color: #ff304d;
}
.backBotton{
  margin: 0px 0px 10px 0px;
}